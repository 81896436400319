var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("rest-table", {
    attrs: { endpoint: "albums", filter: _vm.filter, fields: _vm.fields },
    scopedSlots: _vm._u([
      {
        key: "cell(artistTitle)",
        fn: function(data) {
          return [
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "release-detail",
                    params: { id: data.item.AlbumID }
                  }
                }
              },
              [
                _c("strong", [
                  _vm._v(_vm._s(data.item.ProductDetails.Attribution))
                ]),
                _vm._v(" - " + _vm._s(data.item.ProductDetails.Title) + " "),
                data.item.ProductDetails.VersionTitle
                  ? _c("span", [
                      _vm._v(
                        " | " + _vm._s(data.item.ProductDetails.VersionTitle)
                      )
                    ])
                  : _vm._e()
              ]
            )
          ]
        }
      },
      {
        key: "cell(ReleaseDate)",
        fn: function(data) {
          return [
            _vm._v(
              " " + _vm._s(_vm._f("dateFormat")(data.item.ReleaseDate)) + " "
            )
          ]
        }
      },
      {
        key: "cell(CoverArtAvailable)",
        fn: function(data) {
          return [
            data.item.CoverArtAvailable == "1"
              ? _c(
                  "span",
                  { staticStyle: { "font-weight": "bold", color: "green" } },
                  [_vm._v("V")]
                )
              : _vm._e(),
            data.item.CoverArtAvailable == "0"
              ? _c(
                  "span",
                  { staticStyle: { "font-weight": "bold", color: "red" } },
                  [_vm._v("X")]
                )
              : _vm._e()
          ]
        }
      },
      {
        key: "cell(actions)",
        fn: function(data) {
          return [
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "release-detail",
                    params: { id: data.item.AlbumID }
                  }
                }
              },
              [_c("b-icon-pencil-square")],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }